<template>
<div class="text-sm-subtitle-1 text-lg-h5 ml-6 mb-2 text-uppercase">Monitors
    <v-tabs>
        <v-tab><span class="text-sm-caption text-lg-subtitle-1">Triggered </span><span v-if="!$vuetify.breakpoint.mobile" class="text-sm-caption text-lg-subtitle-1 font-weight-light ml-1">Monitors</span></v-tab>
        <v-tab-item>
            <v-card class="elevation-12">
                <v-card-text >
                    <v-text-field
                        v-model="filter[0]"
                        label="Filter"
                        single-line
                        hide-details
                    ></v-text-field>
                    <v-data-table
                        :headers="headers.explorer"
                        :items="history"
                        :search="filter[0]"
                    ></v-data-table>
                </v-card-text>
                <v-card-actions>
                </v-card-actions>
            </v-card>
            <v-subheader
                class="mt-4"
            ></v-subheader>
        </v-tab-item>
        <v-tab><span class="text-sm-caption text-lg-subtitle-1">Manage </span><span v-if="!$vuetify.breakpoint.mobile" class="text-sm-caption text-lg-subtitle-1 font-weight-light ml-1"> Monitors</span></v-tab>
        <v-tab-item>
            <v-card class="elevation-12">
                <v-card-text>
                    <v-text-field
                        v-model="filter[1]"
                        label="Filter"
                        single-line
                        hide-details
                    ></v-text-field>
                    <v-data-table dense hide-default-footer show-expand
                        :headers="headers.monitors"
                        :items="rules"
                        :search="filter[1]"
                    >
                        <template v-slot:expanded-item="{ item, headers }">
                            <td class="edit" :colspan="headers.length">
                                <v-container>
                                    <v-row>
                                        <v-spacer/>
                                        <v-col>
                                            <json-editor v-bind:key="item.id" :ref="`JsonEditor_${item.id}`" :schema="item.meta.schema" v-model="model[`${item.id}`]">
                                                <v-btn x-small outlined @click="submit" class="ma-1">Update</v-btn>
                                                <v-btn x-small outlined @click="reset(item.id)" class="ma-1">Reset</v-btn>
                                                <v-progress-linear indeterminate class="mt-4"
                                                    :active="progress[item.id].active"
                                                    :background-color="progressBackgroundColor(item.id)"
                                                    :color="progressColor(item.id)"
                                                    height="2px">
                                                </v-progress-linear>
                                            </json-editor>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </td>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                </v-card-actions>
            </v-card>
            <v-subheader
                class="mt-4"
            ></v-subheader>
        </v-tab-item>
    </v-tabs>
</div>
</template>
<style scoped>
td .edit {
    height: 100px;
}
.v-data-table tbody tr.v-data-table__expanded__content {
    -webkit-box-shadow: unset;
    box-shadow: unset;
}
</style>
<script>
import { VForm, VSelect, VCombobox, VTextField } from "vuetify/lib";
import JsonEditor from '@667/vue-json-ui-editor';
//import JsonEditor from 'vue-json-ui-editor';

function getComponentRules(vm, field) {
    let rules = [ v => !!v || 'required value' ];
    switch(vm.$vnode.data.key + '_' + field.name) {
        case 'cpu-usage_min':
        case 'memory-usage_min':
        case 'cpu-usage_max':
        case 'memory-usage_max':
            rules.push(v => v >= 0 || `must be a positive integer value`);
            rules.push(v => v <= 100 || `must be less than or equal to 100`); break;
        case 'long-timeout_timeout':
            rules.push(v => v >= 0 || `must be a positive integer value`); break;
    }
    return rules;
}
JsonEditor.setComponent('form', VForm, () => {
});
JsonEditor.setComponent('select', VSelect, ({ vm, field }) => {
    return {
        rules: getComponentRules(vm, field),
        label: field.name,
        items: field.items.map(item => item.value)
    }
});
JsonEditor.setComponent('number', VTextField, ({ vm, field }) => {
    return ({
        rules: getComponentRules(vm, field),
        label: field.name
    })
});
JsonEditor.setComponent('text', VTextField, ({ vm, field }) => {
    return ({
        rules: getComponentRules(vm, field),
        label: field.name
    })
});
JsonEditor.setComponent('vcombobox', VCombobox, ({ field }) => {
    return ({
        items: field.value || [],
        value: field.value ? field.value : [],
        multiple: true,
        'small-chips': true,
        label: field.name
    })
});
export default {
  name: "MonitorsPanel",
  components: {
      JsonEditor 
  },
  data() {
    return {
        filter: ['', ''],
        headers: {
            explorer: [
                { text: "Date", value: 'unixDateStamp', width: '10%' },
                { text: "Host", value: 'host', width: '15%' },
                { text: "Severity", value: 'severity' },
                //{ text: "Rule", value: 'rule', width: '20%' },
                { text: "Message", value: 'message', width: '60%' }
            ],
            monitors: [
                { text: "Id", value: 'id' },
                { text: "Description", value: 'meta.docs.description' },
                { text: "Category", value: 'meta.docs.category' },
            ]
        },
        rules: [],
        model: {
            'cpu-usage': {},
            'library-mismatch': {},
            'long-timeout': {},
            'memory-usage': {}
        },
        progress: {
            'cpu-usage': { active: false, state: false },
            'library-mismatch': { active: false, state: false },
            'long-timeout': { active: false, state: false },
            'memory-usage': { active: false, state: false }
        },
        settings: {
            progressVisibilityTimeout: 3000
        }
    }
  },
  async mounted() {
    let self = this;
    let { settings } = self.$auth.user[process.env.VUE_APP_USER_METADATA_VARIABLE_STRING];
    self.rules = await self.$backend.getRules();
    if (self.$auth.user && settings.rules) {
        let rules = Object.entries(settings.rules);
        rules.map(rule => {
            let name = rule[0],
                value = rule[1];
            this.model[name] = value;
        });
    }
  },
  methods: {
    submit(_e) {
        let ruleName = _e.currentTarget.__vue__.$parent.$parent.$vnode.key;
        this.progress[ruleName].active = true;
        this.progress[ruleName].state = true;
        let rules = { [ruleName]: this.model[ruleName]};
        this.$backend.updateRules(rules)
        .then(response => {
            this.progress[ruleName].state = false;
            this.progressVisible(ruleName);
            return response;
        });
    },
    reset(id) {
      this.$refs[`JsonEditor_${id}`].reset();
    },
    progressColor(item) {
        return this.progress[item].state ? "yellow darken-2" : "green";
    },
    progressBackgroundColor(item) {
        return this.progress[item].state ? "yellow darken-1" : "green";
    },
    progressVisible(item) {
        if (this.progress[item].active) {
            setTimeout((() => {
                this.progress[item].active = false;
            }).bind(this), this.settings.progressVisibilityTimeout);
        }
    }
  },
  props: {
      history: Array
  },
  computed: {
  }
};
</script>
