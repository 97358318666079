<template>
    <v-tooltip top>
        <template v-slot:activator="{ on }">
            <span v-on="on">
                Savings:
                <span class="font-italic green--text dollars">${{ saved.grandTotalSaved.split('.')[0] }}</span>.
                <sup class="font-italic green--text cents">{{ saved.grandTotalSaved.split('.')[1] }}</sup>
            </span>
        </template>
        <span v-if="saved.lastSuspendSaved > 0">
            You saved <span class="font-italic green--text dollars ml-1">${{ saved.lastSuspendSaved.split('.')[0] }}</span>.
            <span class="font-italic green--text cents mr-2">{{ saved.lastSuspendSaved.split('.')[1] }}</span> on the last suspend!
        </span>
        <span v-else>
            Savings is recalculated everytime you restore a suspended instance.
        </span>
    </v-tooltip>
</template>
<script>
export default {
    name: 'sunrayStats',
    props: {
        stats: Array
    },
    methods: {
        getDigitaloceanPricing() {
            return {
                snapshot_price_hourly: 0.05/720 /*
                * $0.05/GB per month not available in the API as far as I can tell...
                * 0.5/720 (hours per month) = 6.944444444444444e-5
                */
            }
        },
        getAdjustedSaved(grandTotalSaved, saved) {
            if (!saved) saved = grandTotalSaved

            const costFormulas = {
                'percentage-50': saved => saved * 0.5,
                'percentage-40': saved => saved * 0.4,
                'percentage-30': saved => saved * 0.3,
                'percentage-20': saved => saved * 0.2,
                'percentage-10': saved => saved * 0.1
            }

            let cost

            if (grandTotalSaved <= 10) cost = costFormulas['percentage-50'](saved)
            else if (grandTotalSaved > 10 && grandTotalSaved <= 20) cost = costFormulas['percentage-40'](saved)
            else if (grandTotalSaved > 20 && grandTotalSaved <= 40) cost = costFormulas['percentage-30'](saved)
            else if (grandTotalSaved > 40 && grandTotalSaved <= 80) cost = costFormulas['percentage-20'](saved)
            else if (grandTotalSaved > 80) cost = costFormulas['percentage-10'](saved)
            
            let adjusted = saved - cost
            return adjusted.toFixed(2)
        },
        getAdjustedGrandTotalSaved(grandTotalSaved) {
            return this.getAdjustedSaved(grandTotalSaved)
        },
        round(value, decimals) {
            return Number(Math.round(value+'e'+decimals)+'e-'+decimals)
        }
    },
    computed: {
        saved: function() {
            let self = this,
                lastSuspendSaved = 0,
                grandTotalSaved = 0,
                saved = 0

            if (!self.stats) return
            saved = self.stats.filter(cur => cur.suspended && cur.restored)
            .map(cur => {
                if (!cur.price_hourly || !cur.min_disk_size) return
                let dropletHourRate = cur.price_hourly,
                    snapshotHourRate = self.getDigitaloceanPricing().snapshot_price_hourly * cur.min_disk_size,
                    downtime = cur.restored - cur.suspended

                cur.dropletSaved = dropletHourRate * (downtime / 3600000)
                cur.snapshotSpent = snapshotHourRate * (downtime / 3600000)
                cur.saved = cur.dropletSaved - cur.snapshotSpent
                return cur         
            })
            saved = saved.filter(stat => stat)
            saved = saved.sort((a, b) => a.restored - b.restored) // may always be sorted and this is unnecessary?...
            grandTotalSaved = saved.reduce((acc, cur) => acc + cur.saved, 0)
            lastSuspendSaved = saved.length > 0 ? saved[saved.length -1].saved : 0
            lastSuspendSaved = self.round(lastSuspendSaved, 2).toFixed(2)
            lastSuspendSaved = self.getAdjustedSaved(grandTotalSaved, lastSuspendSaved)
            grandTotalSaved = self.getAdjustedGrandTotalSaved(grandTotalSaved)

            return {
                saved,
                grandTotalSaved: grandTotalSaved.toString(),
                lastSuspendSaved: lastSuspendSaved.toString()
            }
        }
    }
}
</script>