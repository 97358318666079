<template>
    <v-dialog
              v-model="this.active"
              persistent
              max-width="500">
        <v-card>
            <v-row class="mx-0 grey lighten-4">
                <v-spacer/>
                <v-col cols="10" class="d-flex justify-center my-4">
                    <v-icon class="mr-4">fa-share-alt</v-icon>Share
                </v-col>
                <v-col cols="1" class="d-flex justify-end"><v-btn elevation="0" @click="toggle()">X</v-btn></v-col>
            </v-row>
            <v-card-title class="headline">
                Share debugging session 
            </v-card-title>
            <v-card-text>A share link will make your debugging session available to anyone with the link. Sharing your debugging session will allow others to view and interact with the underlying runtime.</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                       color="green darken-1"
                       text
                       :loading="loading"
                       :disabled="shareLink !== '' ? true : false"
                       @click="getShareLink()">
                    Get share link
                </v-btn>
            </v-card-actions>
            <v-divider />
            <div v-if="shareLink !== ''">
                <v-row class="mx-2">
                    <v-col cols="12" class="d-flex justify-center font-italic">Copy the link below and share:</v-col>
                </v-row>
                <v-row class="mx-2">
                    <v-col cols="12" class="d-flex align-center">
                        <v-icon class="mr-2">mdi-link</v-icon>
                        <v-text-field ref="share-link" :value="shareLink" />
                        <v-btn class="ml-6" outlined x-small color="green lighten-2" @click="copyToClipboard()">Copy
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-card>
    </v-dialog>
</template>
<style scoped>
</style>
<script>
export default {
    name: "ShareDialog",
    data() {
        return {
            loading: false,
            shareLink: ''
        };
    },
    computed: {
    },
    methods: {
        getShareLink: function() {
            let self = this;
            self.loading = true;
            self.$backend.getShareLink({cid: self.item.tunnelSocket.cid})
            .then(response => {
                if (response && response.data && response.data.key) {
                    self.shareLink = `https://chrome-devtools-frontend.brakecode.com/inspector.html?experiments=true&v8only=true&wss=${process.env.VUE_APP_API_SERVER.match(/https:\/\/(.*)/)[1]}/share/ws/${response.data.key}/${self.item.tunnelSocket.cid}/${self.item.jsonInfo.id}?runtime=${self.item.name.match(/deno/) ? 'deno' : 'node'}`;
                }
            })
            .finally(() => {
                self.loading = false;
            });
        },
        copyToClipboard: function () {
            let textToCopy = this.$refs['share-link'].$el.querySelector(
                "input"
            );
            textToCopy.disabled = false;
            textToCopy.select();
            document.execCommand("copy");
            textToCopy.disabled = true;
        },
        toggle: function() {
            this.shareLink = '';
            this.$emit('toggle');
        }
    },
    props: {
        item: Object,
        active: Boolean,
    }
};
</script>