<template>
    <v-container class="dashboard px-sm-0">
        <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols sm="12" lg="10" xl="8">
                <v-container class="fill-height" fluid>
                    <v-row align="center" justify="center">
                        <v-col>
                            <v-expansion-panels popout multiple v-model="panels">
                                <v-expansion-panel>
                                    <v-expansion-panel-header>Account</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <div class="text-sm-subtitle-1 text-lg-h5 ml-6 mb-2 text-uppercase">API Keys</div>
                                        <v-card class="elevation-12">
                                            <v-card-text>
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <tbody>
                                                            <tr v-bind:style="{ display: displayStyle }" class="text-sm-caption text-uppercase">
                                                                <th>Name</th>
                                                                <th>Key</th>
                                                                <th>Created</th>
                                                            </tr>
                                                            <tr
                                                                v-bind:style="{ display: displayStyle }"
                                                                v-for="(item, value, index) in dashboard.apikeys"
                                                                :key="`${value}`"
                                                            >
                                                                <td>{{ item.name }}</td>
                                                                <td class="my-1" style="display: flex">
                                                                    <v-text-field dense disabled ref="apikey" :value="value" />
                                                                    <v-btn class="ml-6" outlined x-small color="green lighten-2" @click="copyToClipboard(index)"
                                                                        >Copy
                                                                    </v-btn>
                                                                </td>
                                                                <td>{{ item.created }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-card-text>
                                            <v-card-actions> </v-card-actions>
                                        </v-card>
                                        <v-subheader class="mt-4"
                                            >API Keys are unique to your account. An API Key is required by all agents that submit metrics and events to
                                            Brakecode.</v-subheader
                                        >
                                        <v-divider></v-divider>
                                        <!-- next account card begin -->
                                        <sunray-dialog-update-key class="mt-8" :x-small="false" @hint="keyHintHandler" title="DISCS Settings"></sunray-dialog-update-key>
                                        <!-- next account card end -->
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>Diagnostic Reports</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <!-- start report explorer -->
                                        <div class="text-sm-subtitle-1 text-lg-h5 ml-6 mb-2 text-uppercase">Explorer</div>
                                        <v-card class="elevation-12">
                                            <v-card-text>
                                                <v-container v-if="!historyTreeFormat"> No history was found. </v-container>
                                                <v-treeview v-for="host in historyTreeFormat" dense :key="host.id" :items="host.children">
                                                    <template v-slot:prepend="{ item }">
                                                        <v-icon v-if="item.name">
                                                            {{
                                                                item.name == 'resourceUsage'
                                                                    ? 'mdi-cpu-64-bit'
                                                                    : item.name == 'javascriptStack'
                                                                    ? 'mdi-language-javascript'
                                                                    : item.name == 'environmentVariables'
                                                                    ? 'mdi-variable'
                                                                    : item.name == 'uvthreadResourceUsage'
                                                                    ? 'mdi-speedometer'
                                                                    : item.name == 'header'
                                                                    ? 'mdi-file-chart'
                                                                    : item.name == 'nativeStack'
                                                                    ? 'mdi-language-cpp'
                                                                    : item.name == 'rtk'
                                                                    ? 'mdi-lifebuoy'
                                                                    : item.name == 'userLimits'
                                                                    ? 'mdi-tune-vertical'
                                                                    : item.name == 'javascriptHeap'
                                                                    ? 'mdi-memory'
                                                                    : item.name == 'sharedObjects'
                                                                    ? 'mdi-settings'
                                                                    : item.name == 'libuv'
                                                                    ? 'mdi-nodejs'
                                                                    : ''
                                                            }}
                                                        </v-icon>
                                                    </template>
                                                </v-treeview>
                                            </v-card-text>
                                            <v-card-actions> </v-card-actions>
                                        </v-card>
                                        <v-subheader class="mt-4"></v-subheader>
                                        <MonitorsPanel :history="historyRTKFormat" />
                                        <!-- end report explorer -->
                                        <!-- start report monitors -->
                                        <!--
                <div class="title ml-6 mb-2 text-uppercase">Monitors</div>
                <v-card class="elevation-12">
                    <v-card-text>
                    <v-text-field
                        v-model="filter"
                        label="Filter"
                        single-line
                        hide-details
                    ></v-text-field>
                    <v-data-table
                        :headers="headers"
                        :items="historyRTKFormat"
                        :search="filter"
                    ></v-data-table>
                    </v-card-text>
                    <v-card-actions>
                    </v-card-actions>
                </v-card>
                <v-subheader
                    class="mt-4"
                ></v-subheader>
                -->
                                        <!-- end report monitors -->
                                        <!-- start report summary -->
                                        <!--
                <div class="title ml-6 mb-2 text-uppercase">Summary</div>
                <v-card class="elevation-12">
                    <v-card-text>
                    </v-card-text>
                    <v-card-actions>
                    </v-card-actions>
                </v-card>
                <v-subheader
                    class="mt-4"
                ></v-subheader>
                -->
                                        <!-- end report summary -->
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>PADS</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-data-table
                                            dense
                                            item-key="`${tunnelSocket.cid} ${pid}`"
                                            :single-expand="true"
                                            :expanded.sync="expanded.pads"
                                            :item-class="rowClass"
                                            :headers="headers"
                                            :items="dashboard.pads.connections"
                                            @item-expanded="itemExpandedHandler"
                                        >
                                            <template v-slot:[`item.actions`]="{ item }">
                                                <div class="action-buttons d-flex">
                                                    <v-btn
                                                        v-if="item.tunnelSocket && item.tunnelSocket.cid"
                                                        x-small
                                                        elevation="0"
                                                        class="mr-1"
                                                        v-on:click="openShareDialog(item)"
                                                    >
                                                        <v-icon small>fa-share-alt</v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        v-if="item.tunnelSocket && item.tunnelSocket.cid"
                                                        x-small
                                                        color="green lighten-2"
                                                        width="80px"
                                                        :loading="loading[`${item.tunnelSocket ? item.tunnelSocket.cid : undefined}`]"
                                                        :dark="item.tunnelSocket && item.tunnelSocket.cid ? true : false"
                                                        :disabled="item.tunnelSocket && item.tunnelSocket.cid ? false : true"
                                                        v-on:click.exact="openRemoteDevToolsURL(item)"
                                                        v-on:click.ctrl="openRemoteDevToolsURLWindow(item)"
                                                    >
                                                        devtools
                                                        <template v-slot:loader>
                                                            <span>Opening...</span>
                                                        </template>
                                                    </v-btn>
                                                    <v-btn
                                                        v-if="!item.tunnelSocket || !item.tunnelSocket.cid"
                                                        x-small
                                                        color="green darken-2"
                                                        width="80px"
                                                        outlined
                                                        :disabled="true /*(item.tunnelSocket && item.tunnelSocket.cid) ? true : false*/"
                                                        v-on:click="startInspectSocket(item)"
                                                    >
                                                        inspect
                                                        <template v-slot:loader>
                                                            <span>Starting...</span>
                                                        </template>
                                                    </v-btn>
                                                </div>
                                            </template>
                                            <template v-slot:[`item.host`]="{ item }">
                                                <v-tooltip top open-delay="500">
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on" class="text-truncate" v-bind:class="{ 'item-host': !$vuetify.breakpoint.mobile }">
                                                            {{ item.host }}
                                                        </div>
                                                    </template>
                                                    <span>{{ item.host }}</span>
                                                </v-tooltip>
                                            </template>
                                            <template v-slot:[`item.cmd`]="{ item }">
                                                <v-tooltip top open-delay="500">
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on" class="text-truncate" v-bind:class="{ 'item-cmd': !$vuetify.breakpoint.mobile }">
                                                            {{ item.cmd }}
                                                        </div>
                                                    </template>
                                                    <span>{{ item.cmd }}</span>
                                                </v-tooltip>
                                            </template>
                                            <template v-slot:[`item.connectionId`]="{ item }">
                                                <v-tooltip top open-delay="500">
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on" class="text-truncate" v-bind:class="{ 'item-cid': !$vuetify.breakpoint.mobile }">
                                                            {{ item.tunnelSocket ? item.tunnelSocket.cid : '' }}
                                                        </div>
                                                    </template>
                                                    <span>{{ item.tunnelSocket ? item.tunnelSocket.cid : '' }}</span>
                                                </v-tooltip>
                                            </template>
                                        </v-data-table>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>DISCS (Drive Image Suspend Cloud Services)</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-data-table
                                            dense
                                            item-key="workstation.id"
                                            show-expand
                                            hide-default-footer
                                            :expanded="expanded.sunray"
                                            :headers="headersWorkstations"
                                            :items="sunray.workstations"
                                        >
                                            <template v-slot:top>
                                                <v-toolbar flat>
                                                    <v-toolbar-title>My Managed Cloud Instances</v-toolbar-title>
                                                    <v-divider class="mx-4" inset vertical></v-divider>
                                                    <sunray-stats :stats="dashboard.sunray.stats"></sunray-stats>
                                                    <v-spacer></v-spacer>
                                                    <sunray-dialog-update-key @hint="keyHintHandler"></sunray-dialog-update-key>
                                                    <v-tooltip :disabled="sunray.workstations.length == 0 && keyHint ? true : false" top open-delay="1000">
                                                        <template v-slot:activator="{ on }">
                                                            <!-- https://stackoverflow.com/questions/56370021/display-vuetify-tooltip-on-disabled-button -->
                                                            <div v-on="on" class="d-inline-block">
                                                                <v-btn
                                                                    :disabled="sunray.workstations.length > 0 || !keyHint"
                                                                    color="primary"
                                                                    small
                                                                    outlined
                                                                    class="mb-2"
                                                                    @click.stop="workstations.dialog = true"
                                                                    >Import</v-btn
                                                                >
                                                            </div>
                                                        </template>
                                                        <span v-if="!keyHint"
                                                            >You must first add your cloud provider API Key.</span
                                                        >
                                                        <span v-else
                                                            >Currently, you can only manage a single workstation. If you're interested in management of additional instances, contact
                                                            support@brakecode.com</span
                                                        >
                                                    </v-tooltip>

                                                    <v-dialog v-model="workstations.dialog" max-width="500px">
                                                        <v-card :loading="loading.workstationImport">
                                                            <template slot="progress">
                                                                <v-progress-linear color="yellow darken-2" indeterminate></v-progress-linear>
                                                            </template>
                                                            <v-card-title>
                                                                <span class="caption"
                                                                    >Imported vms/snapshots will be added to your managed cloud instances list.</span
                                                                >
                                                            </v-card-title>

                                                            <v-card-text>
                                                                <v-container>
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <v-select
                                                                                :items="workstations.providers"
                                                                                v-model="workstations.editedItem.provider"
                                                                                label="Cloud Provider"
                                                                            ></v-select>
                                                                        </v-col>
                                                                        <v-col cols="12">
                                                                            <v-text-field
                                                                                v-model="workstations.editedItem.id"
                                                                                :error="workstations.error"
                                                                                :label="workstations.editedItem.type === 'vm' ? 'Droplet Id' : 'Snapshot Name'"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                        <v-col cols="12">
                                                                            <v-radio-group v-model="workstations.editedItem.type" row mandatory>
                                                                                <v-radio label="droplet" value="vm"></v-radio>
                                                                                <v-radio label="snapshot" value="snapshot"></v-radio>
                                                                            </v-radio-group>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                                <v-alert
                                                                    v-if="workstations.error"
                                                                    dense
                                                                    text
                                                                    type="error"
                                                                    transition="scale-transition"
                                                                    icon="fa-exclamation-circle"
                                                                    border="bottom"
                                                                >
                                                                    {{ workstations.error }}
                                                                </v-alert>
                                                            </v-card-text>

                                                            <v-card-actions>
                                                                <v-spacer></v-spacer>
                                                                <v-btn color="blue darken-1" text @click="closeWorkstationDialog"> Cancel </v-btn>
                                                                <v-btn color="blue darken-1" outlined @click="importWorkstation"> Import </v-btn>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </v-dialog>
                                                </v-toolbar>
                                            </template>
                                            <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
                                                <v-btn text @click="expand(true)" v-if="sunray.workstationLogs[item.name] && !isExpanded"
                                                    ><v-icon v-if="sunray.workstationLogs[item.name]" x-small v-bind:class="[ ((!item.restoreJobLock || (item.restoreJobLock && item.restoreJobLock.status != 'ended')) ||
                                                        (!item.suspendJobLock || (item.suspendJobLock && item.suspendJobLock.status != 'ended'))) ? 'fa-terminal-pulsing' : '']"
                                                        >fa-terminal</v-icon
                                                    ></v-btn
                                                >
                                                <v-btn text :ref="`logCloseButton_${item.name}`" @click.stop="expand(false)" v-if="sunray.workstationLogs[item.name] && isExpanded"
                                                    ><v-icon v-if="sunray.workstationLogs[item.name]" x-small color="yellow darken-2">fa-terminal</v-icon></v-btn
                                                >
                                            </template>
                                            <template v-slot:expanded-item="{ headers, item }">
                                                <td v-if="item && sunray.workstationLogs[item.name]" class="text-overline grey--text d-flex justify-end">Job Log:</td>
                                                <td v-if="item && sunray.workstationLogs[item.name]" :colspan="headers.length - 1" class="text-overline grey--text">
                                                    <v-virtual-scroll height="225" item-height="20" :items="sunray.workstationLogs[item.name]">
                                                        <template v-slot:default="{ item, index }">
                                                            <pre :id="`logline-${index}`">{{ item }}</pre>
                                                        </template>
                                                    </v-virtual-scroll>
                                                </td>
                                            </template>
                                            <template v-slot:[`item.specs`]="{ item }">
                                                <v-tooltip top open-delay="500">
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on" class="text-truncate" v-bind:class="{ 'vm-specs': !$vuetify.breakpoint.mobile }">
                                                            <v-chip x-small label color="primary">{{
                                                                item.address.match(/digitalocean_droplet_snapshot/i) ? 'image' : 'vm'
                                                            }}</v-chip>
                                                            {{ item.specs.name }}
                                                        </div>
                                                    </template>
                                                    <span class="subtitle-1">{{
                                                        `${Object.entries(item.specs)
                                                            .filter((kv) => kv[0] !== 'name')
                                                            .map((kv) =>
                                                                kv[0] === 'size' && item.specs.name.match(/image/i)
                                                                    ? `${kv[0]}:${kv[1]} GB`
                                                                    : `${kv[0]}:${kv[1]}`
                                                            )
                                                            .flat()
                                                            .toString()
                                                            .replaceAll(',', ', ')}`
                                                    }}</span>
                                                </v-tooltip>
                                            </template>
                                            <template v-slot:[`item.stats`]="{ item }">
                                                {{ item.specs.name.match(/image/i) ? 'Suspended' : 'Restored' }} {{ item.values.created_at ? $luxon.DateTime.fromISO(item.values.created_at).toRelative() : '' }}
                                            </template>
                                            <template v-slot:[`item.actions`]="{ item }">
                                                <div class="action-buttons d-flex">
                                                    <v-tooltip
                                                        v-if="item.address.match(/digitalocean_droplet_snapshot/i)"
                                                        top
                                                        :disabled="item.values.status != 'active'"
                                                        open-delay="500"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <!-- https://stackoverflow.com/questions/56370021/display-vuetify-tooltip-on-disabled-button -->
                                                            <div v-on="on" class="d-inline-block">
                                                                <v-btn
                                                                    v-if="!item.tainted"
                                                                    class="mr-1"
                                                                    x-small
                                                                    outlined
                                                                    color="primary"
                                                                    width="100px"
                                                                    :loading="loading.restoreWorkstation[item.name]"
                                                                    :disabled="item.values.status && item.values.status.match(/active|new/i) ? true : false"
                                                                    v-on:click.exact="restoreWorkstation(item.name, item.values.name)"
                                                                >
                                                                    restore
                                                                    <template v-slot:loader>
                                                                        <v-progress-circular indeterminate width="1" color="black"
                                                                            >Restoring...</v-progress-circular
                                                                        >
                                                                    </template>
                                                                </v-btn>
                                                            </div>
                                                        </template>
                                                        <span>Powering down your vm is required before suspending to ensure data consistency.</span>
                                                    </v-tooltip>
                                                    <v-tooltip v-else top :disabled="item.values.status != 'active'" open-delay="500">
                                                        <template v-slot:activator="{ on }">
                                                            <!-- https://stackoverflow.com/questions/56370021/display-vuetify-tooltip-on-disabled-button -->
                                                            <div v-on="on" class="d-inline-block">
                                                                <v-btn
                                                                    v-if="!item.tainted"
                                                                    class="mr-1"
                                                                    x-small
                                                                    color="primary"
                                                                    width="100px"
                                                                    :loading="loading.suspendWorkstation[item.name]"
                                                                    :disabled="item.values.status && item.values.status.match(/active|new/) ? true : false"
                                                                    v-on:click.exact="suspendWorkstation(item.name, item.values.id)"
                                                                >
                                                                    suspend
                                                                    <template v-slot:loader>
                                                                        <v-progress-circular indeterminate width="1" color="black"
                                                                            >Suspending...</v-progress-circular
                                                                        >
                                                                    </template>
                                                                </v-btn>
                                                            </div>
                                                        </template>
                                                        <span>Powering down your vm is required before suspending to ensure data consistency.</span>
                                                    </v-tooltip>
                                                    <v-btn
                                                        v-if="item.tainted"
                                                        class="mr-1"
                                                        x-small
                                                        color="secondary"
                                                        width="100px"
                                                        :loading="
                                                            loading[item.address.match(/digitalocean_droplet_snapshot/)
                                                                ? 'removeTaintedWorkstationImage'
                                                                : 'removeTaintedWorkstation'
                                                                ][item.name]
                                                        "
                                                        v-on:click.exact="item.address.match(/digitalocean_droplet_snapshot/)
                                                            ? removeTaintedWorkstationImage(item)
                                                            : removeTaintedWorkstation(item)
                                                        "
                                                    >
                                                        remove
                                                        <template v-slot:loader>
                                                            <v-progress-circular indeterminate width="1" color="black">Removing...</v-progress-circular>
                                                        </template>
                                                    </v-btn>
                                                    <!--
                                                    <v-btn
                                                        x-small
                                                        color="red darken-2"
                                                        width="80px"
                                                        outlined
                                                        :disabled="true"
                                                        v-on:click=""
                                                    >
                                                        delete
                                                        <template v-slot:loader>
                                                            <span>Deleting...</span>
                                                        </template>
                                                    </v-btn>
                                                    -->
                                                </div>
                                            </template>
                                            <template v-slot:[`item.status`]="{ item }">
                                                <v-tooltip v-if="item.tainted" top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" class="mr-2" small>fa-info-circle</v-icon>
                                                    </template>
                                                    <span>This managed resource may have been removed/renamed externally.  Check your provider dashboard.</span>
                                                </v-tooltip>
                                                <span v-if="item.tainted" class="text-uppercase red--text caption">tainted</span>
                                                <span
                                                    v-else
                                                    v-bind:class="item.values.status === 'active' ? 'green--text' : 'grey--text-darken-2'"
                                                    class="text-uppercase caption"
                                                    >{{ !item.values.status && item.specs.name.match(/image/i) ? 'suspended' : item.values.status }}</span
                                                >
                                            </template>
                                        </v-data-table>
                                        <v-alert dismissible type="error" v-model="dashboard.sunray.error">
                                            {{ dashboard.sunray.errorMessage }}
                                        </v-alert>
                                        <v-alert class="mt-4" outlined type="success" v-model="dashboard.sunray.success">
                                            {{ dashboard.sunray.successMessage }}
                                        </v-alert>
                                        <v-banner
                                            class="my-4"
                                            v-bind:class="[sunray.workstations.length === 0 ? 'show-banner' : 'hide-banner']"
                                            elevation="2"
                                        >
                                            <a href="https://m.do.co/c/fe4184318b19" target="_blank" class="mr-12"
                                                ><img
                                                    height="30"
                                                    contain
                                                    :src="require('../../public/DO_Logo_horizontal_blue.svg')"
                                                    alt="cloud server, cloud hosting, VPS server"
                                            /></a>
                                            <span class="text-h6">Get a $100, 60-day credit free to try DigitalOcean!</span>
                                            <template v-slot:actions>
                                                <v-btn color="primary" small outlined href="https://m.do.co/c/fe4184318b19" target="_blank" class="mr-4"
                                                    >Signup</v-btn
                                                >
                                            </template>
                                        </v-banner>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <!--
            <v-expansion-panel>
                <v-expansion-panel-header></v-expansion-panel-header>
                <v-expansion-panel-content>
                </v-expansion-panel-content>
            </v-expansion-panel>
            -->
                            </v-expansion-panels>
                        </v-col>
                    </v-row>
                    <router-view />
                </v-container>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <ShareDialog :item="shareDialogItem" :active="shareDialogActive" @toggle="shareDialogActive = !shareDialogActive"> </ShareDialog>
    </v-container>
</template>
<style>
.v-data-table__mobile-row {
    display: table;
}
.v-data-table {
    white-space: nowrap;
}
.vm-specs {
    width: 100%;
}
.item-host,
.item-cid {
    width: 100px;
}
.item-cmd {
    width: 300px;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: white;
}
tr.fresh {
    background: linear-gradient(45deg, white, rgb(255, 254, 245), white);
}
tr.stale {
    background: linear-gradient(-90deg, white, rgb(240, 240, 240), white);
    transition: opacity 3s ease-out, visibility 3s ease-out;
    transition-delay: 5s;
    opacity: 0;
    visibility: collapse;
}
.v-data-table tbody tr.v-data-table__expanded__content {
    box-shadow: 0px 3px 5px -4px rgb(50 50 50 / 75%), 0 0 0px 0px rgb(50 50 50 / 75%);
    -webkit-box-shadow: inset 0px 3px 5px -4px rgb(50 50 50 / 75%), inset 0 0 0px 0px rgb(50 50 50 / 75%);
}
.fa-terminal-pulsing {
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
@keyframes pulse {
    0% {
        color: black;
    }
    50% {
        color: #fbc02d;
    }
    100% {
        color: black;
    }
}
.v-virtual-scroll {
    overflow-x: auto;
    overflow-y: auto;
}
.show-banner {
    opacity: 1;
    transition: opacity 5s ease-in-out;
}
.hide-banner {
    opacity: 0;
    transition: opacity 5s ease-in-out;
}
</style>
<script>
import axios from 'axios'

import MonitorsPanel from '../components/MonitorsPanel'
import ShareDialog from '../components/ShareDialog'
import SunrayStats from '../components/SunrayStats'
import SunrayDialogUpdateKey from '../components/SunrayDialogUpdateKey.vue'

import utilityMixins from '../mixins/utilities'

const axiosCache = axios.create({
        headers: { 'Cache-Control': 'no-cache' },
    }),
    retryInterval = 1000,
    maxRetries = 3

axiosCache.defaults.raxConfig = {
    instance: axiosCache,
}

export default {
    mixins: [ utilityMixins ],
    components: {
        MonitorsPanel,
        ShareDialog,
        SunrayStats,
        SunrayDialogUpdateKey,
    },
    methods: {
        keyHintHandler: function(value) {
            this.keyHint = value
        },
        itemExpandedHandler: function (event) {
            let { item, value } = event
            this.rowClickHandler(item, value)
        },
        rowClickHandler: function (item) {
            this.getJsonInfo(item.tunnelSocket.cid)
        },
        rowClass: function (item) {
            return typeof this.timeouts[item.pid] == 'object' ? 'stale' : 'fresh'
        },
        copyToClipboard: function (index) {
            let textToCopy = this.$refs.apikey[index].$el.querySelector('input')
            textToCopy.disabled = false
            textToCopy.select()
            document.execCommand('copy')
            textToCopy.disabled = true
        },
        generateAPIKey: function () {
            this.$backend.generateAPIKey({ app: 'pad' }).then((response) => Object.assign(this.dashboard.pads.apikeys, response))
        },
        getJsonInfo: function (cid, retries = 0) {
            let self = this,
                jsonInfoURL = `${process.env.VUE_APP_API_SERVER}/json/${cid}`

            self.$set(self.loadingJsonInfoURL, cid, true)

            return axiosCache
                .get(jsonInfoURL, {
                    headers: {
                        Authorization: `Bearer ${self.token}`,
                    },
                })
                .then((response) => {
                    self.$set(self.jsonInfo, cid, response.data[0])
                    self.$set(self.loadingJsonInfoURL, cid, false)
                    return Promise.resolve(response.data[0])
                })
                .catch((error) => {
                    if (retries > maxRetries) {
                        self.$set(self.loadingJsonInfoURL, cid, false)
                        error
                    } else {
                        setTimeout(() => {
                            console.log(`Retrying (attempt ${retries} of ${maxRetries}) inspector json info url...`)
                            self.getJsonInfo(cid, ++retries)
                        }, retryInterval * retries)
                    }
                })
        },
        openRemoteDevToolsURLWindow: function (item) {
            return this.openRemoteDevToolsURL(item, true)
        },
        openRemoteDevToolsURL: function (item, modifier) {
            this.$debugger.openRemoteDevToolsURL({
                caller: this,
                item,
                modifier,
            })
        },
        startInspectSocket: function (item, retries = 0) {
            console.log(retries)
        },
        openPanel: function () {
            switch (this.$route.query.panel) {
                case 'account':
                    this.panels.push(0)
                    break
                case 'reports':
                    this.panels.push(1)
                    break
                case 'pads':
                    this.panels.push(2)
                    break
                case 'sunray':
                    this.panels.push(3)
                    break
            }
        },
        openShareDialog: function (item) {
            let cid = item.tunnelSocket.cid
            let p = !this.jsonInfo[cid] ? this.getJsonInfo(cid) : Promise.resolve(this.jsonInfo[cid])

            p.then(() => {
                item.jsonInfo = this.jsonInfo[cid]
                this.shareDialogItem = item
                this.shareDialogActive = true
            })
        },
        closeWorkstationDialog: function () {
            this.workstations.dialog = false
            this.$nextTick(() => {
                this.workstations.editedItem = Object.assign({}, this.workstations.defaultItem)
                this.workstations.editedIndex = -1
            })
        },
        importWorkstation: async function () {
            this.$store.commit('refreshPaused', true)

            this.$store.commit('updateLoadingState_workstationImport', true)
            if (this.xhrsInProgress.listWorkstations) {
                let response = await this.waitForPriorXHR(this)
                response
            }
            this.$backend.sunray
                .importWorkstations(this.workstations.editedItem)
                .then((response) => {
                    let name = response.data[0].name,
                        vmName = response.data[0].specs.name

                    this.$store.commit('updateLoadingState_workstationImport', false)
                    this.$store.commit('updateWorkstationLogs', { name, value: undefined })
                    if (response.data.length == 0) {
                        this.workstations.error = `No matching cloud resource was found for ${this.workstations.editedItem.id}`
                    } else {
                        this.closeWorkstationDialog()
                        this.$store.commit('updateWorkstations', response.data)
                        if (this.workstations.editedItem.type === 'vm')
                            // && !localStorage.vm_name_default)
                            localStorage.vm_name_default = vmName
                        if (this.workstations.editedItem.type === 'snapshot')
                            // && !localStorage.image_name_default)
                            localStorage.image_name_default = this.workstations.editedItem.id

                        this.$store.commit('refreshPaused', false)
                    }
                })
                .catch((error) => {
                    error
                    this.$store.commit('updateLoadingState_workstationImport', false)
                    this.$store.commit('refreshPaused', false)
                })
        },
        removeTaintedWorkstation: async function (item) {
            let name = item.name,
                address = item.address,
                vm_id = item.values.name

            this.$store.commit('refreshPaused', true)
            this.$store.commit('updateLoadingState_removeTaintedWorkstation', { name, value: true })
            if (this.xhrsInProgress.listWorkstations) {
                let response = await this.waitForPriorXHR(this)
                response
            }
            this.$backend.sunray
                .removeTaintedWorkstation(address, vm_id)
                .then(response => {
                    response
                    this.$store.commit('updateLoadingState_removeTaintedWorkstation', { name, value: false })
                    this.sunray.workstations.splice(
                        this.sunray.workstations.findIndex((workstation) => workstation.name === name),
                        1
                    )
                    this.$store.commit('refreshPaused', false)
                })
                .catch(error => {
                    this.showError(error)
                    this.$store.commit('updateLoadingState_removeTaintedWorkstation', { name, value: false })
                    this.$store.commit('refreshPaused', false)
                })
        },
        removeTaintedWorkstationImage: async function (item) {
            let name = item.name,
                address = item.address,
                vm_id = item.values.name

            this.$store.commit('refreshPaused', true)
            this.$store.commit('updateLoadingState_removeTaintedWorkstationImage', { name, value: true })
            if (this.xhrsInProgress.listWorkstations) {
                let response = await this.waitForPriorXHR(this)
                response
            }
            this.$backend.sunray
                .removeTaintedWorkstationImage(address, vm_id)
                .then(response => {
                    response
                    this.$store.commit('updateLoadingState_removeTaintedWorkstationImage', { name, value: false })
                    this.sunray.workstations.splice(
                        this.sunray.workstations.findIndex((workstation) => workstation.name === name),
                        1
                    )
                    this.$store.commit('refreshPaused', false)
                })
                .catch(error => {
                    this.showError(error)
                    this.$store.commit('updateLoadingState_removeTaintedWorkstationImage', { name, value: false })
                    this.$store.commit('refreshPaused', false)
                })
        },
        restore_suspend: async function (name, id, action) {
            let actionFunction = `${action}Workstation`,
                processState = `${action}Processes`

            this.$store.commit(`updateLoadingState_${action}Workstation`, { name, value: true })
            this.$store.commit('refreshPaused', true)

            let response = await this.waitForPriorXHR(this)
            response

            try {
                let options =
                    action === 'suspend'
                        ? { vm_id: id, image_id: localStorage.image_name_default || 'brakecode-managed-image' }
                        : { vm_id: localStorage.vm_name_default || 'brakecode-managed-vm', image_id: id }

                this.dashboard.sunray[processState][name] = await this.$backend.sunray[actionFunction](options)

                let interval = setInterval(
                    async function () {
                        if (this.dashboard.sunray[processState][name] === 'ok') {
                            delete this.dashboard.sunray[processState][name]
                            this.$store.commit(`updateLoadingState_${action}Workstation`, { name, value: false })
                            this.$store.commit('refreshPaused', false)
                            this.sunray.workstations.splice(
                                this.sunray.workstations.findIndex((workstation) => workstation.name === name),
                                1
                            )
                            this.showSuccessMessage(action)
                            if (this.$refs[`logCloseButton_${name}`]) this.$refs[`logCloseButton_${name}`].$el.click()
                            clearInterval(interval)
                        } else if (this.dashboard.sunray[processState][name] === 'error') {
                            this.$store.commit(`updateLoadingState_${action}Workstation`, { name, value: false })
                            this.$store.commit('refreshPaused', false)
                            clearInterval(interval)
                            throw new Error(`Failed ${action} workstation!`)
                        }
                    }.bind(this),
                    5000
                )
            } catch (error) {
                error
            }
        },
        restoreWorkstation: async function (name, image_id) {
            this.$store.commit('updateWorkstationLogs', { name, value: undefined })
            await this.restore_suspend(name, image_id, 'restore')
        },
        suspendWorkstation: async function (name, vm_id) {
            this.$store.commit('updateWorkstationLogs', { name, value: undefined })
            await this.restore_suspend(name, vm_id, 'suspend')
        },
        showError(error) {
            this.dashboard.sunray.error = true
            this.dashboard.sunray.errorMessage = error.message
        },
        showSuccessMessage(action) {
            let self = this
            self.dashboard.sunray.successMessage = `${action} succeeded.`
            self.dashboard.sunray.success = true
            setTimeout(() => {
                self.dashboard.sunray.success = false
                self.dashboard.sunray.successMessage = ''
            }, 3000)
            
        }
    },
    computed: {
        displayStyle: function () {
            return this.$vuetify.breakpoint.mobile ? 'block' : ''
        },
        sunray: function() {
            return {
                workstations: this.$store.state.sunray.workstations,
                refreshPaused: this.$store.state.sunray.refreshPaused,
                workstationLogs: this.$store.state.sunray.workstationLogs
            }
        },
        panels: {
            get: function() { return this.$store.state.dashboard.activePanels },
            set: function(val) { this.$store.commit('activeDashboardPanels', val) }
        },
        loading: function() { return this.$store.state.loading }
    },
    asyncComputed: {
        token: async function () {
            return await this.$backend.$auth.getTokenSilently()
        },
    },
    data() {
        return {
            listResponseChecks: 0,
            keyHint: undefined,
            inspectorWindows: {},
            token: null,
            auth: this.$auth,
            dashboard: {
                apikeys: null,
                pads: {
                    metadata: {},
                    connections: [],
                },
                sunray: {
                    error: false,
                    success: false,
                    successMessage: '',
                    errorMessage: '',
                    refreshInterval: 10000,
                    suspendProcesses: {},
                    restoreProcesses: {},
                    stats: [],
                },
            },
            history: undefined,
            historyTreeFormat: undefined,
            historyRTKFormat: undefined,
            headers: [
                { text: 'Host', value: 'host', width: this.$vuetify.breakpoint.mobile ? 10 : 100 },
                { text: this.$vuetify.breakpoint.mobile ? 'PID' : 'Process Id', value: 'pid', width: this.$vuetify.breakpoint.mobile ? 12 : 120 },
                { text: this.$vuetify.breakpoint.mobile ? 'CMD' : 'Process Command', value: 'cmd' },
                { text: 'Port', value: 'inspectPort', width: this.$vuetify.breakpoint.mobile ? 10 : 80 },
                { text: this.$vuetify.breakpoint.mobile ? 'CID' : 'Connection Id', value: 'connectionId', width: this.$vuetify.breakpoint.mobile ? 25 : 250 },
                { text: 'Actions', value: 'actions', width: this.$vuetify.breakpoint.mobile ? 15 : 150 },
            ],
            headersWorkstations: [
                { text: 'Name', value: 'name', width: this.$vuetify.breakpoint.mobile ? 10 : 100 },
                { text: 'Provider', value: 'provider', width: this.$vuetify.breakpoint.mobile ? 10 : 100 },
                { text: 'VM/Image', value: 'specs', width: this.$vuetify.breakpoint.mobile ? 250 : 200 },
                { text: 'Stats', value: 'stats', width: this.$vuetify.breakpoint.mobile ? 150 : 200 },
                { text: 'Actions', value: 'actions', width: this.$vuetify.breakpoint.mobile ? 15 : 150 },
                { text: 'Status', value: 'status', width: this.$vuetify.breakpoint.mobile ? 15 : 50 },
            ],
            loadingJsonInfoURL: {},
            timeouts: {},
            jsonInfo: {},
            expanded: {
                pads: [],
                sunray: [],
            },
            shareDialogItem: {},
            shareDialogActive: false,
            workstations: {
                dialog: false,
                defaultItem: {
                    id: localStorage.vm_name_default || 'brakecode-managed-vm',
                    provider: 'digitalocean',
                    type: '',
                },
                providers: ['digitalocean'],
                editedItem: {
                    id: localStorage.vm_name_default || 'brakecode-managed-vm',
                    provider: 'digitalocean',
                    type: '',
                },
            },
            xhrsInProgress: {
                listWorkstations: false,
            },
        }
    },
    watch: {
        'workstations.dialog': function (val) {
            this.$store.commit('refreshPaused', val)
        }
    },
    destroyed() {
        if (this.interval) clearInterval(this.interval)
    },
    mounted() {
        let self = this
        if (this.$route.query.panel) self.openPanel()
        self.$backend.getDashboard().then(async (response) => {
            Object.assign(self.dashboard, response)
            let io = await self.$backend.startSocket(Object.keys(self.dashboard.apikeys)[0])
            io.on('metadata', (metadata) => {
                /**
                 *  If there is a cid use it to compare the pid of the new connection with that of the old to see if the Node process has restarted, in which case the inspector
                 *  socket remains the same and is re-used.
                 *
                 * */
                let currentConnections = self.dashboard.pads.metadata[metadata.uuid] ? self.dashboard.pads.metadata[metadata.uuid].connections : undefined
                if (currentConnections) {
                    let nonExpiredConnections = Object.values(currentConnections).filter((connection) => connection.expired === undefined)
                    nonExpiredConnections
                        .filter((connection) => connection.tunnelSocket && connection.tunnelSocket.cid)
                        .map((connection) => {
                            let pid = connection.pid,
                                cid = connection.tunnelSocket.cid
                            let new_pid = Object.values(metadata.connections).find(
                                (connection) => connection.tunnelSocket && connection.tunnelSocket.cid === cid
                            )

                            if (new_pid) new_pid = new_pid.pid
                            if (cid) {
                                if (new_pid && new_pid != pid) {
                                    currentConnections[pid].expired = Date.now()
                                    console.log(`new inspect pid: ${new_pid} detected for cid: ${cid}`)
                                    self.$debugger.updateSession(cid, self.getJsonInfo)
                                }
                            }
                        })
                }

                if (self.dashboard.pads.metadata[metadata.uuid] === undefined) {
                    self.dashboard.pads.metadata[metadata.uuid] = {}
                    Object.assign(self.dashboard.pads.metadata[metadata.uuid], metadata)
                } else {
                    Object.assign(self.dashboard.pads.metadata[metadata.uuid].connections, metadata.connections)
                }
                // Remove connections from table after a timeout
                Object.keys(metadata.connections).map((pid) => {
                    let connection = self.dashboard.pads.metadata[metadata.uuid].connections[pid]
                    if (self.timeouts[connection.pid]) clearTimeout(self.timeouts[connection.pid])
                    self.timeouts[connection.pid] = setTimeout(() => {
                        let index = self.dashboard.pads.connections.findIndex((conn) => conn.pid == pid)
                        if (index !== -1) {
                            self.timeouts[connection.pid] = {
                                expired: Date.now(),
                            }
                        }
                    }, 15000)
                })

                self.dashboard.pads.connections = Object.values(self.dashboard.pads.metadata)
                    .map((metadata) =>
                        Object.values(metadata.connections).map((conn) => ({
                            uuid: metadata.uuid,
                            host: metadata.host,
                            tunnelSocket: conn.tunnelSocket ? conn.tunnelSocket : undefined,
                            ...conn,
                        }))
                    )
                    .flat()
            }).on('sunrayProcess', (payload) => {
                let { status, data } = payload
                let name = 'sunray' // this won't work bacause it's not always sent ---> data.stdout.values.root_module.resources[0].name

                if (status && self.sunray.workstationLogs[name] === undefined) {
                    status = [status]
                } else if (status) {
                    status = self.sunray.workstationLogs[name].concat(status)
                }
                self.$store.commit('updateWorkstationLogs', { name, value: status })
                if (status.toString().match(/Workstation\ssuspend\scomplete/i)) {
                    self.dashboard.sunray.suspendProcesses[name] = 'ok'
                    self.$store.commit(`updateLoadingState_suspendWorkstation`, { name, value: false })
                }
                if (status.toString().match(/Workstation\srestore\scomplete/i)) {
                    self.dashboard.sunray.restoreProcesses[name] = 'ok'
                    self.$store.commit(`updateLoadingState_restoreWorkstation`, { name, value: false })
                }
                if (status.toString().match(/Error:\s/i)) {
                    self.$store.commit(`updateLoadingState_suspendWorkstation`, { name, value: false })
                    self.$store.commit(`updateLoadingState_restoreWorkstation`, { name, value: false })
                    self.showError(new Error(status.toString().match(/Error:\s.*/i)[0]))
                }
            })
        })
        self.$backend
            .getHistory()
            .then((response) => (self.history = response))
            .then(() => {
                if (!self.history || !self.history.channels) return
                let channels = Object.keys(self.history.channels)
                let channelsSortedByHost = channels.map((channel) => {
                    let reduced = self.history.channels[channel].reduce((accumulator, entry) => {
                        let rvalue = accumulator[entry.message.host]
                            ? (function () {
                                  accumulator[entry.message.host].push(entry)
                                  return accumulator
                              })()
                            : Object.assign(accumulator, {
                                  [entry.message.host]: new Array(entry),
                              })
                        return rvalue
                    }, {})
                    return reduced
                })
                if (channelsSortedByHost.length === 0) return
                channelsSortedByHost = Object.entries(channelsSortedByHost[0])
                self.historyTreeFormat = channelsSortedByHost.map((channel) => {
                    let channelName = channel[0],
                        channelValues = channel[1]
                    let mapped = channelValues.map((entry, i, all) => ({
                        i,
                        all,
                        id: self.$uuid.v4(),
                        name: `${new Date(Math.ceil(entry.timetoken / 10000))}`,
                        children: formatMessage(entry.message.report, entry.timetoken),
                    }))
                    return {
                        id: self.$uuid.v4(),
                        children: [
                            {
                                id: self.$uuid.v4(),
                                name: channelName,
                                children: mapped,
                            },
                        ],
                    }
                })
                self.historyRTKFormat = channels
                    .map((channel) => {
                        let channelName = channel
                        channel = self.history.channels[channel]
                        return channel.map((entry) => {
                            let unixDateStamp = Math.ceil(entry.timetoken / 10000)
                            let datestring = `${new Date(Math.ceil(entry.timetoken / unixDateStamp))}`
                            let host = entry.message.host
                            if (!entry.message.report) return
                            return entry.message.report.rtk.inspect.map((inspect) => ({
                                id: self.$uuid.v4(),
                                channelName,
                                host,
                                datestring,
                                unixDateStamp,
                                severity: inspect.severity,
                                rule: JSON.stringify(inspect.data),
                                message: inspect.message,
                            }))
                        })
                    })
                    .flat(2)
            })

        self.$backend.sunray.getStats().then((stats) => {
            if (!stats) return

            stats = Object.entries(stats)
                .filter((stat) => stat[1].restored && stat[1].suspended)
                .map((stat) => ({
                    ['workstation_id']: stat[0],
                    ['downtime']: stat[1].restored - stat[1].suspended,
                    ...stat[1],
                }))

            self.dashboard.sunray.stats = stats
        })
        function oneCheckOnRefreshAndStartInterval() {
            self.$backend.sunray
                .listWorkstations({
                    vm_id: localStorage.vm_name_default || 'brakecode-managed-vm',
                    image_id: localStorage.image_name_default || 'brakecode-managed-image',
                })
                .then(response => {
                    self.$store.commit('updateWorkstations', response.data)
                    self.xhrsInProgress.listWorkstations = false
                })

            if (self.interval) clearInterval(self.interval)
            self.interval = setInterval(function () {
                Object.keys(self.loading).map(async (loadingStateKey) => {
                    let match = loadingStateKey.match(/(suspend|restore)Workstation/) // only interested in suspend or restore state key
                    if (match) {
                        Object.entries(self.loading[loadingStateKey]).map(async loadingState => {
                            let name = loadingState[0],
                                state = loadingState[1]

                            if (typeof state != 'string') return // typeof check is because we are using boolean and workstation_id
                            try {
                                let job = await self.$backend.sunray[`${match[1]}WorkstationCheck`]({ workstation_id: name })

                                if (job.status !== 'pending') {
                                    self.$store.commit(`updateLoadingState_${match[0]}`, { name, value: false })
                                    this.$store.commit('refreshPaused', false)
                                }
                            } catch (error) {
                                error
                            }
                        })
                    }
                })
                if (self.panels.includes(3) && !self.xhrsInProgress.listWorkstations && !self.sunray.refreshPaused && self.keyHint) {
                    self.xhrsInProgress.listWorkstations = true
                    self.$backend.sunray
                        .listWorkstations({
                            vm_id: localStorage.vm_name_default || 'brakecode-managed-vm',
                            image_id: localStorage.image_name_default || 'brakecode-managed-image',
                        })
                        .then(response => {
                            if (response.data.length !== 0) {
                                self.$store.commit('updateWorkstations', response.data)
                            } else if (response.data.length === 0 && self.listResponseChecks <= 3) {
                                self.listResponseChecks++
                            } else if (response.data.length === 0 && self.listResponseChecks > 3) {
                                self.$store.commit('updateWorkstations', response.data)
                                self.listResponseChecks = 0
                            }
                            self.xhrsInProgress.listWorkstations = false
                        })
                }
            }, self.dashboard.sunray.refreshInterval)
        }
        oneCheckOnRefreshAndStartInterval()
        function formatMessage(message, timetoken) {
            let mapped = Object.entries(message).map((kv) => {
                let key = kv[0],
                    value = kv[1]
                if (value && value.constructor.name === 'Object') {
                    return {
                        id: self.$uuid.v4(),
                        name: key,
                        test: 'object',
                        children: formatMessage(value, timetoken),
                    }
                } else if (value && value.constructor.name === 'Array') {
                    return {
                        id: self.$uuid.v4(),
                        name: key,
                        test: 'array',
                        children: mapArray(value),
                    }
                } else {
                    //} else if (value && value.constructor.name === 'String') {
                    return {
                        id: self.$uuid.v4(),
                        name: `${key}: ${value}`,
                    }
                }
            })
            return mapped
        }
        function mapArray(array) {
            let mapped = formatMessage(array)
            /*let mapped = array.map(element => ({
          id: self.$uuid.v4(),
          name: element
      }));*/
            return mapped
        }
    },
}
</script>
