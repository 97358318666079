<template>
    <div>
        <v-btn color="primary" v-bind:x-small="xSmall" outlined class="mb-2 mr-1" @click.stop="dialog = true">{{ title }}</v-btn>
        <v-dialog v-model="dialog" max-width="500px">
            <v-card :loading="loading">
                <template slot="progress">
                    <v-progress-linear color="yellow darken-2" indeterminate></v-progress-linear>
                </template>
                <v-card-title>
                    <span class="caption text-break">Provider keys are encrypted and securely stored for backend communication with your provider.</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-select :items="providers" v-model="provider" label="Cloud Provider"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-form ref="form">
                                    <v-text-field
                                        v-bind:class="passwordCompression"
                                        v-model="editedItem.key"
                                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show ? 'text' : 'password'"
                                        name="provider-key"
                                        :hint="`${
                                            provider.value === 'digitalocean' ? 'Personal access token (' + provider.text + ')' : provider.text + ' API Key'
                                        }`"
                                        :error="error"
                                        label="Cloud Provider API Key"
                                        :rules="Object.values(rules)"
                                        @click:append="show = !show"
                                    ></v-text-field>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-alert v-if="error" dense text type="error" transition="scale-transition" icon="fa-exclamation-circle" border="bottom">
                        {{ errorMessage }}
                    </v-alert>
                    <v-alert v-if="success" dense text type="success" transition="scale-transition" border="bottom">
                        {{ success }}
                    </v-alert>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="cancel"> Cancel </v-btn>
                    <v-btn color="blue darken-1" outlined @click="update_save">{{ this.keyHint ? 'Update' : 'Save' }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<style>
@import url('https://fonts.googleapis.com/css2?family=BenchNine:wght@300;400;700&display=swap');

input[name="provider-key"] {
    text-align: center;
    font-family: 'BenchNine', sans-serif;
}
.password input[name="provider-key"] {
    font-size: x-large;
}
.password-compressed-1 input[name="provider-key"] {
    font-size: larger;
}
.password-compressed-2 input[name="provider-key"] {
    font-size: large;
}
</style>
<script>
import Vue from 'vue'
import AsyncComputed from 'vue-async-computed'

Vue.use(AsyncComputed)

export default {
    name: 'sunrayDialogUpdateKey',
    props: {
        title: {
            type: String,
            default: 'Settings'
        },
        xSmall: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return {
            success: false,
            providers: [{ text: 'Digitalocean', value: 'digitalocean' }],
            show: false,
            provider: { text: 'Digitalocean', value: 'digitalocean' },
            error: false,
            dialog: false,
            loading: false,
            defaultItem: {
                key: this.keyHint,
                provider: { text: 'Digitalocean', value: 'digitalocean' },
            },
            editedItem: {
                key: this.keyHint,
                provider: { text: 'Digitalocean', value: 'digitalocean' },
            },
            rules: {
                empty: true,
                minlength: true,
                counter: true,
                noasterisks: true,
                nochange: true
            }
        }
    },
    computed: {
        passwordCompression: function() {
            if(this.editedItem.key && this.editedItem.key.length >= 45 && this.editedItem.key.length < 55) {
                return 'password-compressed-1' 
            } else if (this.editedItem.key && this.editedItem.key.length >= 55) {
                return 'password-compressed-2'
            } else {
                return 'password'
            }
        }
    },
    methods: {
        update_save() {
            let self = this

            this.activateRules()
            this.$nextTick(() => {
                if (!this.$refs.form.validate()) return
                
                this.loading = true
                this.$backend.sunray
                    .updateProviderApiKey(this.editedItem)
                    .then(response => {
                        this.loading = false
                        this.success = response
                        setTimeout(() => self.cancel(), 1500)
                    })
                    .catch(error => {
                        this.error = true
                        this.errorMessage = error
                        this.loading = false
                    })
            })
        },
        cancel() {
            this.deactivateRules()
            this.dialog = false
            this.success = false
            this.editedItem = Object.assign({}, this.defaultItem)
            this.$asyncComputed.keyHint.update()
        },
        deactivateRules() {
            this.rules = {
                empty: true,
                minlength: true,
                counter: true,
                noasterisks: true,
                nochange: true
            }
        },
        activateRules() {
            this.rules = {
                empty: value => value !== '' || 'Must not be empty.',
                minlength: value => value.length >= 64 || 'Min 64 characters.',
                counter: value => value.length <= 80 || 'Max 80 characters.',
                noasterisks: value => {
                    const pattern = /^(\*)+/
                    return !pattern.test(value) || 'Must not contain asterisks.'
                },
                nochange: value => {
                    return value !== this.keyHint || 'Must change the password to update it.'
                }
            }
        }
    },
    watch : {
        keyHint: function(val) {
            this.editedItem.key = val ? `...${val}` : ''
            this.defaultItem.key = val ? `...${val}` : ''
            this.$emit('hint', val ? `...${val}` : val)
        }
    },
    asyncComputed: {
        keyHint: async function () {
            return await this.$backend.sunray
                .getProviderApiKey()
                .then(hint => {
                    this.loading = false
                    return hint
                })
                .catch(error => {
                    error
                    this.loading = false
                    return false
                })
        },
    },
}
</script>
