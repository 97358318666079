import { render, staticRenderFns } from "./MonitorsPanel.vue?vue&type=template&id=bb7ec690&scoped=true&"
import script from "./MonitorsPanel.vue?vue&type=script&lang=js&"
export * from "./MonitorsPanel.vue?vue&type=script&lang=js&"
import style0 from "./MonitorsPanel.vue?vue&type=style&index=0&id=bb7ec690&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_j7knk6mol3vthgvrc443rvn57u/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb7ec690",
  null
  
)

export default component.exports