export default {
    methods: {
        waitForPriorXHR(dashboard, passedResolve) {
            if (this.timeout) clearTimeout(this.timeout)
            if (dashboard.xhrsInProgress.listWorkstations) {
                console.log('Waiting for in progress XHR...')
                return new Promise(resolve => {
                    if (passedResolve) passedResolve()
                    this.timeout = setTimeout(this.waitForPriorXHR.bind(this), 3000, dashboard, resolve)
                })
            } else {
                console.log('Resuming from waitForPriorXHR.')
                if (passedResolve) passedResolve()
                return Promise.resolve()
            }
        }
    }
}